import { useStaticQuery, graphql } from "gatsby";

const useGalleryQuery = () => {
    const galleryQuery = useStaticQuery(graphql`
        query GalleryQuery {
            allFile(filter: { absolutePath: { regex: "/images/gallery/" } }) {
                edges {
                    node {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
    `);
    return galleryQuery;
};

export default useGalleryQuery;
