import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Styles from "./styles";
import { useIntl } from "gatsby-plugin-react-intl";
import { Element } from "react-scroll";
import useGalleryQuery from "./useGalleryQuery";

const { GalleryWrapper, H2Title } = Styles;

const Gallery = () => {
    const intl = useIntl();
    const { allFile } = useGalleryQuery();
    return (
        <Element name="gallery">
            <GalleryWrapper>
                <H2Title>{intl.formatMessage({ id: "gallery" })}</H2Title>
                <Carousel infiniteLoop={true}>
                    {allFile.edges.map((i) => (
                        <div>
                            <GatsbyImage
                                image={i.node.childImageSharp.gatsbyImageData}
                                alt="kiosk"
                            />
                        </div>
                    ))}
                </Carousel>
            </GalleryWrapper>
        </Element>
    );
};

export default Gallery;
