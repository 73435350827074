import React, { useState } from "react";
import Styles from "./styles";
import ITextInput from "./types";

const { InputWrapper, TextInput, LabelWrapper, InputLabel } = Styles;

const InputText = ({ placeholder }: ITextInput) => {
    const [isActive, setIsActive] = useState(false);
    return (
        <InputWrapper>
            {isActive && (
                <LabelWrapper>
                    <InputLabel>{placeholder}</InputLabel>
                </LabelWrapper>
            )}
            <TextInput
                onFocus={() => setIsActive(true)}
                onBlur={() => setIsActive(false)}
                type="text"
                placeholder={placeholder}
            />
        </InputWrapper>
    );
};
export default InputText;
