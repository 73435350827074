import styled from "styled-components";
import { Container } from "../../Styled";

const Styles = {
    Wrapper: styled.div`
        background-color: ${(props) => props.theme.color.violet};
        width: 100%;
        display: flex;
        justify-content: center;
    `,
    FooterContainer: styled(Container)`
        height: 300px;
    `,
};

export default Styles;
