import React from "react";
import Styles from "./styles";
import { GatsbyImage } from "gatsby-plugin-image";
import { useIntl } from "gatsby-plugin-react-intl";
import useInfoQuery from "./useInfoQuery";

const {
    Wrapper,
    ImageWrapper,
    DescWrapper,
    DoubleGrid,
    H2Title,
    PInfo,
} = Styles;

const Info = () => {
    const intl = useIntl();
    const { allFile } = useInfoQuery();
    return (
        <>
            <Wrapper>
                <ImageWrapper>
                    <GatsbyImage
                        style={{
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                        }}
                        imgStyle={{
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            objectFit: "fill",
                        }}
                        image={
                            allFile.edges[0].node.childImageSharp
                                .gatsbyImageData
                        }
                        alt="info background"
                    />
                </ImageWrapper>
                <DoubleGrid>
                    <DescWrapper>
                        <H2Title>
                            {intl.formatMessage({ id: "infoTitle" })}
                        </H2Title>
                        <PInfo>
                            {intl.formatMessage({ id: "infoDescription" })}
                        </PInfo>
                    </DescWrapper>
                    <GatsbyImage
                        imgStyle={{
                            objectFit: "contain",
                        }}
                        image={
                            allFile.edges[1].node.childImageSharp
                                .gatsbyImageData
                        }
                        alt="kiosk2"
                    />
                </DoubleGrid>
            </Wrapper>
        </>
    );
};

export default Info;
