import React from "react";
import Styles from "./styles";
import { useIntl } from "gatsby-plugin-react-intl";

const { Wrapper, Btn, BtnShadow } = Styles;

const Button = () => {
    const intl = useIntl();
    return (
        <Wrapper>
            <Btn onClick={() => {}} type="submit">
                {intl.formatMessage({ id: "send" })}
            </Btn>
            <BtnShadow />
        </Wrapper>
    );
};

export default Button;
