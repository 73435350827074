import styled from "styled-components";
import { Container } from "../../Styled";

const Styles = {
  Wrapper: styled.div`
    position: relative;
    width: 100%;
    height: 1100px;
    display: flex;
    justify-content: center;
    margin-top: 100px;
    @media (max-width: 500px) {
      margin-top: 0px;
    }
  `,
  ImageWrapper: styled.div`
    position: absolute;
    height: 1400px;
    width: 100%;
    @media (max-width: 1250px) {
      height: 1500px;
    }
    @media (max-width: 500px) {
      height: 1360px;
    }
  `,
  DoubleGrid: styled(Container)`
    width: 1200px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-auto-flow: row;
    margin-top: 180px;
    align-items: center;
    @media (max-width: 1250px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    @media (max-width: 500px) {
      width: 100%;
      margin-top: 200px;
    }
  `,
  DescWrapper: styled.div`
    display: flex;
    flex-direction: column;
    z-index: 40;
    padding-right: 50px;
    width: 500px;
    @media (max-width: 1250px) {
      margin-top: 80px;
      margin-bottom: 40px;
      padding-right: 0px;
    }
    @media (max-width: 500px) {
      width: 100%;
      margin-right: 15px;
      margin-left: 15px;
      padding-right: 0px;
    }
  `,
  H2Title: styled.h2`
    color: white;
    font-size: 48px;
    @media (max-width: 500px) {
      font-size: 34px;
    }
  `,
  PInfo: styled.p`
    color: black;
    font-size: 16px;
    margin: 0;
  `,
};

export default Styles;
