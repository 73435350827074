import styled from "styled-components";
import { Container } from "../../Styled";

const Styles = {
    Wrapper: styled(Container)`
        position: relative;
        width: 1200px;
        margin: 0px 0px 200px 0px;
        @media (max-width: 1220px) {
            width: 100%;
        }
    `,
    DoubleGrid: styled.div`
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        grid-auto-flow: row;
        margin-top: 100px;
        gap: 20px 0;
        @media (max-width: 1250px) {
            grid-template-columns: 1fr;
        }
        @media (max-width: 500px) {
            margin-top: 0px;
        }
    `,
    H2Title: styled.h2`
        margin-left: 35px;
        margin-right: 35px;
        font-size: 48px;
        color: ${(props) => props.theme.color.violet};
        font-weight: 700;
        @media (max-width: 500px) {
            font-size: 34px;
        }
    `,
    TextAreaWrapper: styled.div`
        position: relative;
        height: 300px;
        margin: 50px 20px 200px 20px;
    `,
    TextArea: styled.textarea`
        outline: none;
        height: 100%;
        width: calc(100% - 180px);
        border: 1px black solid;
        border-top-left-radius: 200px;
        border-bottom-right-radius: 200px;
        z-index: 30;
        color: black;
        padding: 80px;
        font-size: 18px;
        transition: 0.2s;
        resize: none;
        &:focus {
            border: 1px ${(props) => props.theme.color.violet} solid;
        }
        @media (max-width: 500px) {
            border-top-left-radius: 90px;
            border-bottom-right-radius: 90px;
            padding: 40px;
            height: calc(100% + 80px);
            width: calc(100% - 80px);
        }
    `,
    TextAreaShadow: styled.div`
        content: "";
        position: absolute;
        border-top-left-radius: 200px;
        border-bottom-right-radius: 200px;
        height: calc(100% + 160px);
        width: calc(100% - 20px);
        left: -10px;
        top: 10px;
        background-color: ${(props) => props.theme.color.lightViolet};
        z-index: 35;
        &:hover {
            cursor: text;
        }
        @media (max-width: 500px) {
            border-top-left-radius: 90px;
            border-bottom-right-radius: 90px;
            width: 100%;
        }
    `,
    LabelWrapper: styled.div`
        position: relative;
        width: 400px;
        height: 0px;
    `,
    InputLabel: styled.label`
        top: -9px;
        left: 200px;
        position: absolute;
        color: ${(props) => props.theme.color.violet};
        background-color: white;
        padding: 2px 5px 2px 5px;
        font-size: 12px;
    `,
    ButtonWrapper: styled.div`
        width: 100%;
        display: flex;
        justify-content: center;
    `,
};

export default Styles;
