import React from "react";
import Layout from "../components/Layout";
import Landing from "../components/Landing";
import Services from "../components/Services";
import Info from "../components/Info";
import Contact from "../components/Contact";
import Gallery from "../components/Gallery";
import Footer from "../components/Footer";

const IndexPage = () => (
  <Layout>
    <Landing />
    <Services />
    <Info />
    <Gallery />
    <Contact />
    <Footer />
  </Layout>
);

export default IndexPage;
