import React from "react";
import Styles from "./styles";

const { FooterContainer, Wrapper } = Styles;

const Footer = () => {
    return (
        <Wrapper>
            <FooterContainer></FooterContainer>
        </Wrapper>
    );
};

export default Footer;
