import React, { useRef, useState } from "react";
import Styles from "./styles";
import { useIntl } from "gatsby-plugin-react-intl";
import TextInput from "./TextInput";
import Button from "./Button";
import { Element } from "react-scroll";

const {
    Wrapper,
    DoubleGrid,
    H2Title,
    TextAreaWrapper,
    TextArea,
    TextAreaShadow,
    LabelWrapper,
    InputLabel,
    ButtonWrapper,
} = Styles;

const Contact = () => {
    const intl = useIntl();
    const textarea = useRef(null);
    const [isActive, setIsActive] = useState(false);
    return (
        <Element name="contact">
            <Wrapper>
                <H2Title>{intl.formatMessage({ id: "contactWithUs" })}</H2Title>
                <DoubleGrid>
                    <TextInput
                        placeholder={intl.formatMessage({ id: "nameSurname" })}
                    />
                    <TextInput
                        placeholder={intl.formatMessage({ id: "companyName" })}
                    />
                    <TextInput
                        placeholder={intl.formatMessage({ id: "email" })}
                    />
                    <TextInput
                        placeholder={intl.formatMessage({ id: "phoneNumber" })}
                    />
                </DoubleGrid>
                <TextAreaWrapper>
                    {isActive && (
                        <LabelWrapper>
                            <InputLabel>
                                {intl.formatMessage({ id: "message" })}
                            </InputLabel>
                        </LabelWrapper>
                    )}
                    <TextArea
                        onFocus={() => setIsActive(true)}
                        onBlur={() => setIsActive(false)}
                        ref={textarea}
                        placeholder={intl.formatMessage({ id: "message" })}
                    />
                    <TextAreaShadow onClick={() => textarea.current.focus()} />
                </TextAreaWrapper>
                <ButtonWrapper>
                    <Button />
                </ButtonWrapper>
            </Wrapper>
        </Element>
    );
};

export default Contact;
