import styled from "styled-components";

const Styles = {
    Wrapper: styled.div`
        position: relative;
        height: 100px;
    `,
    Btn: styled.button`
        position: relative;
        padding: 15px 40px 15px 40px;
        color: white;
        background-color: ${(props) => props.theme.color.violet};
        border-radius: 30px;
        z-index: 20;
        outline: none;
        border: none;
        transition: 0.3s;
        &:hover {
            cursor: pointer;
            background-color: violet;
        }
    `,
    BtnShadow: styled.div`
        position: absolute;
        left: 5px;
        top: -5px;
        border: 1px solid black;
        z-index: 15;
        width: 112px;
        height: 40px;
        border-radius: 30px;
        opacity: 0.4;
    `,
};

export default Styles;
