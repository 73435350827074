import styled from "styled-components";
import { Container } from "../../Styled";

const Styles = {
    GalleryWrapper: styled(Container)`
        margin: 350px 0 80px 0;
        display: flex;
        flex-direction: column;

        text-align: center;
        @media (max-width: 500px) {
            margin-top: 240px;
            margin-bottom: 0px;
        }
    `,
    H2Title: styled.h2`
        font-size: 48px;
        color: ${(props) => props.theme.color.violet};
        font-weight: 700;
        @media (max-width: 500px) {
            font-size: 34px;
        }
    `,
};

export default Styles;
