import { useStaticQuery, graphql } from "gatsby";

const useInfoQuery = () => {
    const infoQuery = useStaticQuery(graphql`
        query infoQuery {
            allFile(
                filter: { name: { in: ["infoBackground", "kiosk2"] } }
                sort: { fields: name }
            ) {
                edges {
                    node {
                        childImageSharp {
                            gatsbyImageData(layout: CONSTRAINED)
                        }
                    }
                }
            }
        }
    `);
    return infoQuery;
};

export default useInfoQuery;
