import styled from "styled-components";

const Styles = {
    TextInput: styled.input`
        outline: none;
        height: 56px;
        width: 100%;
        border: 1px black solid;
        border-radius: 50px;
        padding: 0 30px 0 30px;
        transition: 0.2s;
        font-size: 18px;
        &:focus {
            border: 1px ${(props) => props.theme.color.violet} solid;
        }
    `,
    LabelWrapper: styled.div`
        position: relative;
        width: auto;
        height: 0px;
    `,
    InputLabel: styled.label`
        top: -9px;
        left: 27px;
        position: absolute;
        color: ${(props) => props.theme.color.violet};
        background-color: white;
        padding: 2px 5px 2px 5px;
        font-size: 12px;
        white-space: nowrap;
    `,
    InputWrapper: styled.div`
        margin-left: 30px;
        margin-right: 30px;
        display: flex;
    `,
};

export default Styles;
